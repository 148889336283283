import React, { useState } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";

const DataFetcher = () => {
  const [url, setUrl] = useState("");
  const [chartData, setChartData] = useState({});

  const fetchData = async () => {
    try {
      const response = await axios.get(url);
      const data = response.data; // Adaptez cette ligne selon le format de votre réponse
      // Supposons que `data` est déjà le bon format pour chart.js
      setChartData({
        labels: data.map((item) => item.x),
        datasets: [
          {
            label: "Cap. Change Rate (%)",
            data: data.map((item) => item.y),
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Entrez l'URL de l'API"
      />
      <button onClick={fetchData}>Charger les données</button>
      {chartData.labels && <Line data={chartData} />}
    </div>
  );
};

export default DataFetcher;
